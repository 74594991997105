import { createContext, useState, useContext, ReactNode } from 'react';

interface ToastContextType {
  toastTitle: string;
  toastOpen: boolean;
  setToastTitle: (title: string) => void;
  setToastOpen: (open: boolean) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toastTitle, setToastTitle] = useState('');
  const [toastOpen, setToastOpen] = useState(false);

  return (
    <ToastContext.Provider
      value={{ toastTitle, toastOpen, setToastTitle, setToastOpen }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
